import React, { useEffect } from "react";
import Photo1 from "./partials/HomePageScreenshot.png";
import Photo2 from "./partials/MeterChanges.png";
import Photo3 from "./partials/UtilityQuestionsScreenshot.png";
import Photo4 from "./partials/UtilityQuestionsScreenshotModal.png";
import Photo5 from "./partials/ChooseAccount.png";

export default function LandingPageBody() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
        overflow: "hidden",
        fontFamily: "'Roboto', sans-serif",
        color: "#fff",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(https://ik.imagekit.io/39dbkqr1gb/LandingPageImage.jpeg?updatedAt=1728942534073)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.5,
          zIndex: -1,
          backgroundAttachment: "fixed",
        }}
      ></div>

      {/* <div style={{ textAlign: "center", padding: "40px 10px", backgroundColor: "rgba(0, 0, 0, 0.7)", borderRadius: "10px", margin: "20px" }}>
        <h1 style={{ fontSize: "3em", marginBottom: "20px" }}>VCS Quick Reads</h1>
       
      </div> */}

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gap: "20px",
          padding: "20px",
          position: "relative",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex: 1 }}>
            <img
              src={Photo1}
              alt="Feature 1"
              style={{ width: "100%", maxWidth: "300px", borderRadius: "10px", marginBottom: "10px" }}
            />
          </div>
          <div style={{ marginLeft: "10px", backgroundColor: "rgba(0, 0, 0, 0.7)", padding: "20px", borderRadius: "10px", flex: 1 }}>
            <h2 style={{ marginBottom: "10px" }}>VCS Quick Reads</h2>
            <p>The essential tool for site managers.</p>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <div style={{ marginRight: "10px", backgroundColor: "rgba(0, 0, 0, 0.7)", padding: "20px", borderRadius: "10px", flex: 1 }}>
            <h2 style={{ marginBottom: "10px" }}>Handle Meter Changes</h2>
            <p>Post meter changes for your property with VCS Quick Reads.</p>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex: 1 }}>
            <img
              src={Photo2}
              alt="Feature 2"
              style={{ width: "100%", maxWidth: "300px", borderRadius: "10px", marginBottom: "10px" }}
            />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex: 1 }}>
            <img
              src={Photo3}
              alt="Feature 3"
              style={{ width: "100%", maxWidth: "300px", borderRadius: "10px", marginBottom: "10px" }}
            />
            <img
              src={Photo4}
              alt="Feature 4"
              style={{ width: "100%", maxWidth: "300px", borderRadius: "10px", marginBottom: "10px" }}
            />
          </div>
          <div style={{ marginLeft: "10px", backgroundColor: "rgba(0, 0, 0, 0.7)", padding: "20px", borderRadius: "10px", flex: 1 }}>
            <h2 style={{ marginBottom: "10px" }}>Answer Utility Questions</h2>
            <p>Get notified of utility questions for your property and respond easily by submitting a current read and an image of the utility meter in question.</p>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <div style={{ marginRight: "10px", backgroundColor: "rgba(0, 0, 0, 0.7)", padding: "20px", borderRadius: "10px", flex: 1 }}>
            <h2 style={{ marginBottom: "10px" }}>Manage Multiple Properties</h2>
            <p>With VCS Quick Reads, you can manage all your properties utility questions and more in one place.</p>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex: 1 }}>
            <img
              src={Photo5}
              alt="Feature 2"
              style={{ width: "100%", maxWidth: "300px", borderRadius: "10px", marginBottom: "10px" }}
            />
          </div>
        </div>

      </div>
      <div
        style={{
          textAlign: "center",
          padding: "50px 20px",
          backgroundColor: "#048dc9",
          borderRadius: "10px",
          margin: "20px",
        }}
      >
        <h1>Coming soon to the Google Play Store and Apple App Store</h1>
        <div style={{ marginTop: "20px" }}>
          <i className="fa-brands fa-google-play fa-4x" style={{ margin: "0 20px" }}></i>
          <i className="fa-brands fa-app-store-ios fa-4x" style={{ margin: "0 20px" }}></i>
        </div>
      </div>
    </div>
  );
}
